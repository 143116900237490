import axios from "axios";

const api = axios.create();

let tenantId = ""; // Default value
let tenantIdResolved = false; // Tracks whether tenantId has been received
let resolveTenantIdPromise; // Function to resolve the Promise

// Create a Promise to queue API requests
const tenantIdPromise = new Promise((resolve) => {
  resolveTenantIdPromise = resolve;
});

// Add a message listener to receive the tenantId from the parent window
console.log("messagereceived-instance");
window.addEventListener("message", (event) => {
  console.log("messagereceived", event.data, event.origin);
  const receivedTenantId = event.data.data;

  if (receivedTenantId) {
    tenantId = receivedTenantId;
    tenantIdResolved = true;
    resolveTenantIdPromise(); // Resolve the Promise when tenantId is received
    console.log("Tenant ID received:", tenantId);
  }
});

// Intercept API requests and wait for tenantIdPromise to resolve
api.interceptors.request.use(async (config) => {
  if (!tenantIdResolved) {
    await tenantIdPromise; // Wait until tenantId is resolved
  }
  config.headers["tenant-id"] = tenantId;
  return config;
});

export default api;
